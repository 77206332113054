// NAVBAR

.nav-main {
  padding-left: 9vw;
  padding-right: 9vw;
  .brand-logo {
    img {
      width: 100%;
    }
  }
  .navbar-collapse {
    @media (min-width: 992px) {
      justify-content: space-between;
    }
    .search-bar {
      position: relative;
      margin-left: 25px;
      .search-icon {
        position: absolute;
        z-index: 1000;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        left: 15px;
        opacity: 0.8;
      }
      .search-bar-input {
        input {
          width: 25vw;
          background-color: rgba(255, 255, 255, 0.23);
          border-radius: 35px;
          border: solid 1px rgba(255, 255, 255, 0.33);
          padding: 20px 10px 21px 40px;
          color: $color-white;
          font-size: $font-16px;
          &:focus {
            box-shadow: none;
          }
          &::placeholder {
            color: $color-white;
            opacity: 0.5;
          }
        }
      }
    }
    .nav-bar-links {
      .nav-bar-link {
        color: $color-white !important;
        font-size: $font-16px;
        padding: 8px 18px;
        margin: 0 35px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        &:last-child {
          border: solid 1px rgba(255, 255, 255, 0.52);
        }
        img {
          width: 13px;
          height: 13px;
          margin-right: 10px;
        }
      }
    }
  }
}

// FOOTER
.footer {
  width: $side-wid;
  margin: 4rem auto;
  .footer-inner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: $font-16px;
      color: $paragraph;
    }
    .social-media-icons {
      a {
        margin: 0 5px;
      }
    }
  }
}

@media (min-width: 320px) and(max-width: 767px) {
  .nav-main .navbar-collapse .search-bar .search-bar-input input {
    width: 100%;
  }
  .nav-main .navbar-collapse .search-bar {
    margin-left: unset;
    margin-top: 20px;
  }
  .nav-main .navbar-collapse .nav-bar-links .nav-bar-link {
    margin: 10px 0;
    justify-content: center;
  }

  .footer .footer-inner-div {
    display: block;
  }
}
