// SLIDER
.home-slide-main {
  height: 100vh;
  width: 100%;
  background-image: url("../../images/slide-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .slide-parent-div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100vh;
    width: 100%;
    background: #00000088;
    color: $color-white;
    .slider {
      text-align: center;
      display: flex;
      align-items: center;
      .previousButton,
      .nextButton {
        display: none;
      }
      div {
        h2 {
          font-size: 40px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.18;
          letter-spacing: normal;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        h6 {
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.18;
          letter-spacing: normal;
          width: 70%;
          margin: auto;
        }
        .listing-link {
          font-size: 22px;
          font-weight: 100;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          cursor: pointer;
          margin-top: 25px;
        }
      }
    }
  }
}

// CATEGORY
.category-section {
  width: $side-wid;
  margin: 4rem auto;
  .title {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: $title-color;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  .my-tabs {
    margin: 0 4rem;
    border-radius: 7px;
    box-shadow: 0 3px 6px 0 rgba(31, 78, 178, 0.25);
    justify-content: space-around;
    border: none;
    .nav-link {
      font-size: 20px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.17;
      letter-spacing: normal;
      color: $gray-color;
      padding: 10px 25px;
      &:hover {
        border: none;
      }
    }
    .nav-link.active {
      border: none;
      font-weight: 500;
      border-bottom: 3px solid $title-color;
      color: $title-color;
    }
  }
}

// WHYUS

.why-us-section {
  background: $card-bg;
  .why-us-inner {
    width: $side-wid;
    padding: 3rem 0;
    margin: auto;
    .title {
      font-size: 28px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      color: $color-white;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
    .row {
      .col-md-3 {
        .small-cards {
          background: $color-white;
          padding: 20px;
          border-radius: 7px;
          h5 {
            color: $title-color;
            margin: 16px 0;
            font-weight: 500;
            font-size: 20px;
          }
          p {
            font-size: $font-16px;
            color: $paragraph;
          }
        }
      }
    }
  }
}

// TOP DEALS
.top-deals-section {
  width: $side-wid;
  margin: 4rem auto;
  .title {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: $title-color;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  .deals-card {
    position: relative;
    background-color: #e9f3fb;
    margin: 1rem;
    border-radius: 7px;
    img {
      width: 100%;
    }
    .deals-content {
      position: absolute;
      top: 20%;
      left: 0;
      z-index: 1;
      padding: 0 2.5rem;
      .deals-first-title,
      .deals-second-title {
        color: #1f4eb2;
        margin: 16px 0;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 15px;
      }
      .deals-second-title {
        color: #518361;
      }
      .listing-link {
        font-size: 16px;
        font-weight: 100;
        cursor: pointer;
        margin-top: 2rem;
      }
    }
  }
  .bg-change {
    background-color: #f8e7d4;
  }
}

// CHAT SECTION
.chat-section {
  background: $card-bg;
  margin-top: 10rem;
  .chat-inner-section {
    position: relative;
    width: $side-wid;
    padding: 4rem;
    margin: auto;
    color: $color-white;
    .chat-img-div {
      position: absolute;
      width: 500px;
      right: 0;
      top: -115px;
      img {
        width: 100%;
      }
    }
    h5 {
      margin: 16px 0;
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      margin: 2rem 0;
    }
    .chat-now-link {
      font-size: 14px;
      font-weight: 100;
      cursor: pointer;
    }
  }
}

// CARD
.category-card-main {
  .row {
    margin-top: 4rem;
    justify-content: center;
    .col-md-4 {
      padding: 0;
      a {
        &:hover {
          text-decoration: none;
        }
        .category-card {
          cursor: pointer;
          border: solid 1px rgba(112, 112, 112, 0.22);
          padding: 7px;
          border-radius: 7px;
          margin: 15px;
          &:hover {
            background: $card-bg;
            .category-content {
              h6 {
                color: white;
              }
              p {
                color: white;
              }
            }
          }
          .category-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 15px 0px;
            h6 {
              margin: 0;
              color: #677d7d;
              font-size: 16px;
              font-weight: 400;
              width: 70%;
            }
            p {
              margin: 0;
              color: #677d7d;
              font-size: 12px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and(max-width: 767px) {
  .home-slide-main .slide-parent-div .slider div h2 {
    font-size: 25px;
  }
  .home-slide-main .slide-parent-div .slider div h6 {
    font-size: 15px;
    font-weight: 300;
  }
  .home-slide-main .slide-parent-div .slider div .listing-link {
    font-size: 18px;
    margin-top: 10px;
  }
  .category-section .my-tabs {
    justify-content: center;
  }
  .category-section .my-tabs .nav-link {
    font-size: 18px;
    text-align: center;
  }
  .why-us-section .why-us-inner .row .col-md-3 .small-cards {
    margin-bottom: 25px;
  }
  .chat-section {
    overflow: hidden;
  }
  .chat-section .chat-inner-section .chat-img-div {
    position: absolute;
    width: 259px;
    bottom: -47px;
    right: -136px;
    top: unset;
  }
}
